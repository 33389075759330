<template>
  <div>
    <b-modal
      :id="'delete-organization-' + modalId"
      v-model="modalShow"
      title="Delete Organization"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
    >
      <div v-if="deleteInProgress" class="flex"><b-spinner class="mx-auto" /></div>
      <div v-else>
        <div v-if="deleteOrganizationFailed" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        <div v-else>
          <strong>Are you sure you want to delete {{ organizationName }} organization?</strong>
        </div>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button :disabled="deleteInProgress" size="sm" variant="success" @click="deleteOrganization()">
          Confirm
        </b-button>
        <b-button :disabled="deleteInProgress" class="ml-2" size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import OrganizationServiceV2 from '@/lib/organization-service-v2';

export default {
  props: {
    modalId: {
      type: String
    },
    organizationName: {
      type: String
    }
  },
  data() {
    return {
      modalShow: false,
      errorMessage: null,
      deleteOrganizationFailed: false,
      deleteInProgress: false
    };
  },

  methods: {
    async deleteOrganization() {
      this.deleteInProgress = true;

      try {
        await OrganizationServiceV2.deleteOrganization(this.modalId);

        this.modalShow = false;

        this.$emit('deleteSuccess', {});
      } catch (error) {
        this.deleteOrganizationFailed = true;
        this.errorMessage = this.parseError(error).message;
      }

      this.deleteInProgress = false;
    },
    async resetForm() {
      this.errorMessage = null;
      this.deleteOrganizationFailed = false;
      this.deleteInProgress = false;
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    }
  }
};
</script>
