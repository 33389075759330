<template>
  <b-container class="mt-5 mb-7">
    <div v-if="!organization">
      <b-spinner />
    </div>
    <div v-else>
      <h1>Organization > {{ organization.name }}</h1>
      <img
        v-if="organization.logoUrl"
        :src="organization.logoUrl"
        :alt="organization.name"
        style="max-height: 200px; max-width: 560px"
        class="mb-5"
      />
      <Alert v-if="errorMessage" variant="red" icon="exclamation">{{ errorMessage }}</Alert>
      <b-tabs v-if="organization">
        <b-tab title="Details" v-if="canEditOrganization" class="mt-4 ml-2" lazy>
          <b-row align-h="start">
            <b-col sm="6" lg="4">
              <EditOrganization :organization="organization" />
            </b-col>
            <b-col sm="4" class="mt-4 ml-4">
              <div class="border border-neutral-300 rounded-md p-4 pt-5">
                <AddTagForm
                  :system="true"
                  :organizationId="organization.id"
                  @onError="onError"
                  @onAddTag="onTagUpdate"
                  class="mb-8 ml-2"
                />
                <ViewTags
                  :organizationId="organization.id"
                  :tags="organization.tags"
                  @onError="onError"
                  @onDeleteTag="onTagUpdate"
                  class="ml-2"
                />
              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Fees" class="mt-4 ml-2" lazy>
          <EditFees />
        </b-tab>
        <b-tab title="Billing" v-if="canEditBilling" class="mt-4 ml-2" lazy>
          <EditBilling />
        </b-tab>
        <b-tab title="Stripe Connect" class="mt-4 ml-2" lazy>
          <ViewStripeConnectDetails :organization="organization" class="mt-4 ml-2" />
        </b-tab>
        <b-tab title="Stripe Deposits" class="mt-4 ml-2" lazy>
          <EditPayout :organization="organization" />
        </b-tab>
        <b-tab title="Invoicing" class="mt-4 ml-2" lazy>
          <InvoicePreferenceDropdown :organization="organization" />
        </b-tab>
      </b-tabs>
    </div>
  </b-container>
</template>
<script>
import ViewStripeConnectDetails from '@/components/ViewStripeConnectDetails';
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import EditFees from '@/components/EditFees';
import EditOrganization from '@/components/admin/EditOrganization';
import EditPayout from '@/components/admin/EditPayout';
import EditBilling from '@/components/forms/EditBilling';
import AddTagForm from '@/components/forms/AddTagForm';
import ViewTags from '@/components/ViewTags';
import InvoicePreferenceDropdown from '@/components/inputs/InvoicePreferenceDropdown';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';
import Alert from '@/components/ui/Alert.vue';

export default {
  components: {
    Alert,
    ViewStripeConnectDetails,
    EditFees,
    EditOrganization,
    EditPayout,
    EditBilling,
    ViewTags,
    AddTagForm,
    InvoicePreferenceDropdown
  },
  data() {
    return {
      errorMessage: '',
      organization: null,
      canEditBilling: false,
      canEditOrganization: false
    };
  },
  async created() {
    try {
      const id = this.$route.query.id;

      this.organization = await OrganizationServiceV2.retrieveOrganization(id);
    } catch (error) {
      this.errorMessage = this.parseError(error).message;
    }
  },
  async mounted() {
    this.canEditBilling = await unleashFeatureEnabled(UnleashKeys.EditBilling);
    this.canEditOrganization = await unleashFeatureEnabled(UnleashKeys.AdminEditOrganization);
  },
  methods: {
    async onTagUpdate() {
      // reload the organization
      this.organization = await OrganizationServiceV2.retrieveOrganization(this.organization.id);
    },
    onError(error) {
      this.errorMessage = this.parseError(error).message;
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 88%;
}

.tab-title {
  font-size: 2rem;
}
</style>
