<template>
  <div>
    <b-alert :show="editPayoutFailed" variant="danger" dismissible fade>
      Failed to edit deposit frequency: {{ errorMessage }}
    </b-alert>
    <b-alert :show="editPayoutSucceeded" variant="success" dismissible fade>
      Successfully saved deposit schedule!
    </b-alert>

    <b-row v-if="stripeAccount">
      <b-col cols="3">
        <b-form @submit.stop.prevent="onSubmit" class="mb-5">
          <b-form-group
            label="Deposit Frequency"
            label-for="input-payout-frequency"
            :invalid-feedback="veeErrors.first('input-payout-frequency')"
          >
            <b-form-select
              name="input-payout-frequency"
              v-model="stripeAccount.providerData.payoutSettings.interval"
              aria-describedby="input-payout-frequency-feedback"
              :options="payoutFrequencyOptions"
              v-validate="{ required: true }"
              :disabled="!canEditPayoutSchedule"
            />
          </b-form-group>

          <b-form-group
            v-if="stripeAccount.providerData.payoutSettings.interval === 'weekly'"
            label="Day of Week"
            label-for="input-weekly-anchor"
            :invalid-feedback="veeErrors.first('input-weekly-anchor')"
          >
            <b-form-select
              name="input-weekly-anchor"
              v-model="stripeAccount.providerData.payoutSettings.weeklyAnchor"
              aria-describedby="input-weekly-anchor-feedback"
              :options="weeklyAnchorOptions"
              v-validate="{ required: true }"
              :disabled="!canEditPayoutSchedule"
            />
          </b-form-group>

          <b-form-group
            v-if="stripeAccount.providerData.payoutSettings.interval === 'monthly'"
            label="Day of Month"
            label-for="input-monthly-anchor"
            :invalid-feedback="veeErrors.first('input-monthly-anchor')"
          >
            <b-form-select
              name="input-monthly-anchor"
              v-model="stripeAccount.providerData.payoutSettings.monthlyAnchor"
              aria-describedby="input-monthly-anchor-feedback"
              :options="monthlyAnchorOptions"
              v-validate="{ required: true }"
              :disabled="!canEditPayoutSchedule"
            />
          </b-form-group>

          <b-form-group class="mt-4">
            <b-button variant="success" @click="onSubmit">Save</b-button>
          </b-form-group>
        </b-form>
      </b-col>
      <b-col>
        <h4 class="mb-4">Stripe Deposit History</h4>
        <PayoutTable
          :organization="organization"
          :stripeAccount="stripeAccount"
          :newPayout="newPayout"
          @refreshed="tableRefreshed()"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import PayoutTable from '@/components/PayoutTable.vue';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

export default {
  components: { PayoutTable },
  props: {
    organization: {
      type: Object
    }
  },
  data() {
    return {
      stripeAccount: null,
      paymentFrequency: null,
      payoutFrequencyOptions: [
        { value: 'manual', text: 'Manual' },
        { value: 'weekly', text: 'Weekly' },
        { value: 'monthly', text: 'Monthly' },
        { value: 'daily', text: 'Daily' }
      ],
      weeklyAnchorOptions: [
        { value: 'sunday', text: 'Sunday' },
        { value: 'monday', text: 'Monday' },
        { value: 'tuesday', text: 'Tuesday' },
        { value: 'wednesday', text: 'Wednesday' },
        { value: 'thursday', text: 'Thursday' },
        { value: 'friday', text: 'Friday' },
        { value: 'saturday', text: 'Saturday' }
      ],
      monthlyAnchorOptions: this.createMonthlyAnchorOptions,
      editPayoutFailed: false,
      errorMessage: null,
      editPayoutSucceeded: false,
      successMessage: null,
      newPayout: false,
      canEditPayoutSchedule: false
    };
  },
  async mounted() {
    this.canEditPayoutSchedule = await unleashFeatureEnabled(UnleashKeys.EditPayoutSchedule);
  },
  created() {
    this.retrieveAccount();
    this.monthlyAnchorOptions = this.createMonthlyAnchorOptions();
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    async retrieveAccount() {
      if (this.organization) {
        if (this.organization.stripeConnectedAccountId) {
          this.stripeAccount = await OrganizationServiceV2.retrieveAccount(this.organization.stripeConnectedAccountId);
        }
      }
    },
    createMonthlyAnchorOptions() {
      let num = 1;
      const monthlyAnchorOptions = [];

      while (num <= 31) {
        monthlyAnchorOptions.push({ value: num, text: `${num}` });
        num++;
      }

      return monthlyAnchorOptions;
    },
    resetForm() {
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    async onSubmit() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      try {
        const payoutSettings = {
          interval: this.stripeAccount.providerData.payoutSettings.interval
        };

        if (payoutSettings.interval === 'weekly') {
          payoutSettings.weeklyAnchor = this.stripeAccount.providerData.payoutSettings.weeklyAnchor;
        } else if (payoutSettings.interval === 'monthly') {
          payoutSettings.monthlyAnchor = this.stripeAccount.providerData.payoutSettings.monthlyAnchor;
        }

        const data = {
          provider: this.stripeAccount.provider,
          payoutSettings: payoutSettings
        };

        await OrganizationServiceV2.updateAccount(this.stripeAccount.id, data);

        this.editPayoutSucceeded = true;
        this.successMessage = 'Payout schedule updated successfully!';
        this.resetForm();
      } catch (error) {
        this.editPayoutFailed = error;
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
    },
    updateTable() {
      this.newPayout = true;
    },
    tableRefreshed() {
      this.newPayout = false;
    }
  }
};
</script>

<style></style>
