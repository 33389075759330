<template>
  <div>
    <b-alert :show="alertType != null" :variant="alertType" dismissible fade>
      {{ alertMessage }}
    </b-alert>
    <b-form @submit.stop.prevent="onSubmit" class="mb-5">
      <b-form-group
        label="Invoice Preference"
        label-for="invoice-preference"
        :invalid-feedback="veeErrors.first('invoice-preference')"
      >
        <b-form-select
          name="invoice-preference"
          v-model="invoicePreference"
          :options="options"
          style="width: 10rem"
        ></b-form-select>
      </b-form-group>

      <b-form-group class="mt-4">
        <b-button variant="success" @click="onSubmit" style="width: 7.5rem">Save</b-button>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import OrganizationServiceV2 from '@/lib/organization-service-v2';

export default {
  props: {
    organization: {
      type: Object
    }
  },
  data() {
    return {
      alertType: null,
      alertMessage: '',
      invoicePreference: this.organization.settings.qboInvoicePreference,
      options: [
        { value: 'manual', text: 'Manual' },
        { value: 'per draw', text: 'Per Draw' },
        { value: 'monthly', text: 'Monthly' }
      ]
    };
  },
  methods: {
    async onSubmit() {
      this.alertType = null;

      const valid = await this.$validator.validateAll();

      if (!valid) {
        return;
      }

      try {
        const data = {
          settings: {
            qboInvoicePreference: this.invoicePreference
          }
        };

        await OrganizationServiceV2.updateOrganization(this.organization.id, data);

        this.alertType = 'success';
        this.alertMessage = 'Invoice preference updated successfully!';
      } catch (error) {
        this.alertType = 'danger';
        if (error.response) {
          this.alertMessage = 'Failed to update invoice preference: ' + error.response.data.errors[0].message;
        } else {
          this.alertMessage = 'An unexpected error occurred, please try again later.';
        }
      }
    }
  }
};
</script>
